import { css } from "@emotion/react"
import { useLocation } from "@reach/router"
import { parse as queryparse } from "query-string"
import React, { useEffect, useState } from "react"
import { Col, Container } from "react-bootstrap"
import Popper from "../../assets/images/party-popper.svg"
import SVGIcon from "../../components/common/SVGIcon"
import Buttons from "../../components/common/button"
import Frame from "../../components/common/frame"
import Slide from "../../components/utility/slide_content"
import "../../styles/pages/community.scss"

const CommunitySuccess = props => {
  const [survey, setSurvey] = useState(false)
  const locationnew = useLocation()
  const searchParams = queryparse(locationnew.search)
  useEffect(() => {
    if (searchParams?.survey == "true") {
      setSurvey(true)
    }
  }, [searchParams.survey])
  return (
    <div className="community">
      <Frame IsFooterVisble="No">
        <main className="community-success">
          <section
            className="hero text-center"
            css={css`
              background: linear-gradient(180deg, #e2e1ff 34.63%, #ffffff 100%);
            `}
          >
            <div className="bg-lines">
              <Container className="Layout-container">
                <Slide delay="200">
                  <SVGIcon name="community/CommunityLogo" className="mt48" />
                </Slide>
                <Slide delay="200">
                  <div>
                    <img
                      src={Popper}
                      alt="popper"
                      className="mt60 mb20 popper-img"
                    />
                  </div>
                  {!survey ? (
                    <h1 className="heading mx-auto fw-bold">Hurray!</h1>
                  ) : (
                    <p className="heading success-heading mx-auto fw-bold">
                      Thank you for taking our survey!
                    </p>
                  )}
                </Slide>
                <Slide delay="200">
                  <div className="p24 mt32 d-flex justify-content-center">
                    <Col lg={5}>
                      {!survey ? (
                        <p className="success-message mx-auto">
                          You’re in line! The community goes live on{" "}
                          <span className="fw-600 deep-purple">
                            November 8th, 2023.
                          </span>
                        </p>
                      ) : (
                        <p className="success-message mx-auto">
                          Keep an eye on your inbox for an invitation to the
                          community and the announcement of the contest winner.
                        </p>
                      )}
                    </Col>
                  </div>
                </Slide>
                {!survey && (
                  <>
                    <Slide delay="200">
                      <Col
                        lg={5}
                        className="d-inline-flex justify-content-center mb16"
                      >
                        <div className="p16 price-container mt24 mb30 justify-content-between">
                          <p className="description success-description fw-500  mb0">
                            Take a quick 1-minute survey and stand a chance to
                            win
                          </p>
                          <span className="fw-800">$500*</span>
                        </div>
                      </Col>
                    </Slide>
                    <Slide delay="200">
                      <Buttons
                        theme="primary-new mb50"
                        arrow
                        text="TAKE SURVEY"
                        link="https://superopscommunity.typeform.com/to/KUEOGfv5"
                      />{" "}
                    </Slide>
                  </>
                )}
              </Container>
            </div>
          </section>
        </main>
      </Frame>
    </div>
  )
}
export default CommunitySuccess
